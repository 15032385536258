import React, { useContext, useState } from "react";
import "./sidebarpage.scss";
import StoryblokClient from "storyblok-js-client";
import {
  MARK_LINK,
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_IMAGE,
  render,
} from "storyblok-rich-text-react-renderer";
import { Link } from "gatsby";
import { getHeadingId, getLink, Image, Table } from "../../utils/helper";
import { CodeDemo } from "../CodeDemo";
import { GlobalContext } from "../../context";
import closeIcon from "../../images/close-svgrepo-com.svg";
import { DialogOverlay } from "@reach/dialog";
import WistiaPlayer from "../WistiaPlayer";
import CustomLink from "../CustomLink";

const SideBarPage = ({ story, location, lang }) => {
  const { data, method } = useContext(GlobalContext);
  const [dialog, SetDialog] = useState(null);
  const { lang: language } = data;

  const content = story?.content;
  const { Content, Headline } = content;
  const setDefaultContent = (name, props) => {
    switch (name) {
      case "Sources_List":
        if (!props?.Title?.trim().toLowercase().startsWith("active")) {
          return;
        }
        return (
          <div className="source-list">
            <Link to={getLink(props?.Link?.cached_url)}>
              <Image src={props?.Image?.filename} alt={props?.Image?.alt} />
              <h5> {props?.Title} </h5>
            </Link>
          </div>
        );
      case "Quick Start Source":
        return (
          <div className="quick-section">
            <h3> {props?.Title} </h3>
            <div className="inner-quick">
              <div className="quick-left">
                <Image src={props?.Image?.filename} alt={props?.Image?.alt} />
              </div>
              <div className="quick-right">
              <CustomLink {...props.Link}> {props?.Link_Label} </CustomLink>
                <h5> {props?.Description} </h5>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div
      className={
        getLink(location?.pathname) == "/docs/sources/"
          ? "sidebar-template source-template"
          : "sidebar-template"
      }
    >
      <div className="article-title">
        <h1> {Headline} </h1>
      </div>

      {render(Content, {
        defaultBlokResolver: (name, props) => {
          return setDefaultContent(name, props);
        },
        markResolvers: {
          [MARK_LINK]: (children, props) => {
            const { linktype, href, target } = props;
            if (linktype === "email") {
              // Email links: add `mailto:` scheme and map to <a>
              return <a href={`mailto:${href}`}>{children}</a>;
            }
            if (href.match(/^(https?:)?\/\//)) {
              // External links: map to <a>
              return (
                <a href={href} target={target}>
                  {children}
                </a>
              );
            }
            // Internal links: map to <Link>
            return <Link to={getLink(href)}>{children}</Link>;
          },
        },
        blokResolvers: {
          ["Table"]: (props) => {
            const { tbody: body, thead: head } = props?.table;
            return <Table head={head} body={body} />;
          },
          ["Two_Column_Table"]: (props) => {
            const { tbody: body, thead: head } = props?.Table;
            return (
              <div className="two-column-table">
                <Table head={head} body={body} />
              </div>
            );
          },
          ["Three_Column_Table"]: (props) => {
            const { tbody: body, thead: head } = props?.Table;
            return (
              <div className="three-column-table">
                <Table head={head} body={body} />
              </div>
            );
          },
          ["level_two_block"]: (props) => {
            return (
              <div className="left-padding-content">
                <p>
                  {" "}
                  <strong>{props?.Title}</strong>{" "}
                </p>
                <p> {props?.Description} </p>
              </div>
            );
          },
          ["Video"]: (props) => {
            return (
              <>
                <iframe
                  src={props?.Url?.url}
                  allowtransparency="true"
                  frameborder="0"
                  scrolling="no"
                  class="wistia_embed"
                  name="wistia_embed"
                  allowfullscreen
                  mozallowfullscreen
                  webkitallowfullscreen
                  oallowfullscreen
                  msallowfullscreen
                  width="620"
                  height="349"
                  loading="lazy"
                ></iframe>
                {/* <script src="//fast.wistia.net/assets/external/E-v1.js" async></script> */}
                {/* <script src="https://fast.wistia.com/embed/medias/ydpd1mdtzm.jsonp" async></script>
                      <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
                      <div className="wistia_responsive_padding" style={{padding: "62.5% 0 0 0",position:"relative",}}>
                      <div className="wistia_responsive_wrapper" style={{height:"100%", left:0, position:"absolute", top:0, width:"100%",}}>
                      <div className="wistia_embed wistia_async_ydpd1mdtzm videoFoam=true" style={{height:"100%", position:"relative", width:"100%"}}>
                      <div className="wistia_swatch" style={{height:"100%", left:0, opacity:0, overflow:"hidden", position:"absolute", top:0, transition:"opacity 200ms", width:"100%"}}>
                      <img src="https://fast.wistia.com/embed/medias/ydpd1mdtzm/swatch" style={{filter:"blur(5px)", height:"100%", width:"100%",}} alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div> */}
              </>
            );
          },
          ["VideoEmbed"]: (props) => {
            return <div dangerouslySetInnerHTML={{ __html: props.source }} />;
          },
          ["wistia_player"]: (props) => {
            return <WistiaPlayer blok={props} key={props._uid} />;
          },
        },
        nodeResolvers: {
          [NODE_CODEBLOCK]: (children) => {
            if (Array.isArray(children)) {
              let code = children.join("");
              return <CodeDemo code={code} />;
            }
            return <CodeDemo code={children} />;
          },
          [NODE_IMAGE]: (children, props) => {
            return dialog === props?.src ? (
              <DialogOverlay
                isOpen={dialog === props?.src}
                onDismiss={() => SetDialog(null)}
                className="overlay-image"
              >
                {/* <Image src={props?.src} alt={props?.alt}/> */}
                <Image src={props?.src} alt={props.alt} />
                <img src={closeIcon} className="closeicon" />
              </DialogOverlay>
            ) : (
              <div onClick={() => SetDialog(props?.src)}>
                <Image src={props?.src} alt={props.alt} />
              </div>
            );
          },
          [NODE_HEADING]: (children, props) => {
            switch (props?.level) {
              case 1:
                return <h1 id={getHeadingId(children)}>{children}</h1>;
              case 2:
                return <h2 id={getHeadingId(children)}>{children}</h2>;
              case 3:
                return <h3 id={getHeadingId(children)}>{children}</h3>;
              case 4:
                return <h4 id={getHeadingId(children)}>{children}</h4>;
              case 5:
                return <h5 id={getHeadingId(children)}>{children}</h5>;
              case 6:
                return <h6 id={getHeadingId(children)}>{children}</h6>;
              default:
                return;
            }
          },
        },
      })}
    </div>
  );
};

export default SideBarPage;
